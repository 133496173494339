.menu {
    position: fixed;
    right: 30px;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
}

.menu ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.menu li {
    width: 50px;
    height: 50px;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    transition: .3s;
    margin: 20px 0;
    border-radius: 50%;
    cursor: pointer;
}

.menu li.enabled {
    pointer-events: none;
}

.menu li.disabled {
    pointer-events: auto;
}

.menu li:hover {
    background: var(--main-primary-color) !important;
}

.menu li.selected {
    background: var(--main-primary-color);
}

.menu li.unselected {
    background: #2b2a2a;
}

.menu h2 {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    color: #fff;
    line-height: 50px;
    font-weight: 500;
    transition: all .3s;
    border-radius: 30px;
    text-transform: uppercase;
    padding: 0 25px 0 30px;
    height: 50px;
    margin: 0;
    background-color: var(--main-primary-color);
    pointer-events: none;
    font-size: 15px;
    letter-spacing: 2px;
    text-align: center;
    white-space: nowrap;
    user-select: none;
}

.menu li:hover h2 {
    opacity: 1;
    padding-right: 50px;
}

.menu i {
    color: #ddd;
    font-size: 19px;
    pointer-events: none;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    top: 15px;
    transition: .3s;
}

.menu.fade-in {
    opacity: 0;
    animation: fade-in 1s ease-in-out forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 768px) {
    .menu {
        position: fixed;
        left: 0;
        top: 100%;
        width: 100%;
        transform: translateY(-100%);
      }
    
      .menu ul {
        width: 100vw;
        display: flex;
        justify-content: space-around;
        padding: 0;
        margin: 0px;
      }
    
      .menu li {
        display: flex;
        width: 100%;
        border-radius: 0;;
        margin: 0;
      }
    
      .menu li h2 {
        font-size: 13px;
        padding: 0 15px;
      }
    
      .menu li:hover h2 {
        padding-right: 20px;
      }
}
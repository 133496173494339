body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111;
  overflow: hidden;
}

:root {
  --main-primary-color: #ffb400;
  --main-secondary-color: #111;
}
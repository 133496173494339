/*.home {
}*/

.color-block {
    position: fixed;
    height: 200%;
    width: 100%;
    transform: rotate(-15deg);
    left: -83%;
    top: -50%;
    background-color: var(--main-primary-color);
    user-select: none;
}

.hero {
    display: block;
    position: fixed;
    background-image: url(/public/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: calc(100vh - 80px);
    width: 33.333%;
    z-index: 11;
    border-radius: 30px;
    left: 40px;
    top: 40px;
    box-shadow: 0 0 7px rgba(0, 0, 0, .9);
    user-select: none;
}

.hero-text {
    position: relative;
    top: 45vh;
    transform: translateY(-50%);
    margin-left: 45%;
    text-align: left;
    max-width: 600px;
}

.hero-text h1 {
    color: var(--main-primary-color);
    font-size: 51px;
    line-height: 62px;
    font-weight: 700;
    margin: 18px 0 10px;
    position: relative;
}

/* Web */
@media only screen and (min-width: 1200px) {
    .hero-text h1::before {
        background-color: var(--main-primary-color);
        content: "";
        position: absolute;
        left: 0;
        top: 29px;
        height: 4px;
        width: 40px;
        border-radius: 10px;
    }

    .hero-text h1 {
        font-size: 40px;
        line-height: 62px;
        padding-left: 70px;
    }
}

/* Small screens - Tablets */
@media only screen and (max-width: 1200px) and (min-width: 1024px) {
    .hero-text h1 {
        font-size: 40px;
        line-height: 52px;
    }

    .hero-text span {
        font-size: 37px;
    }

    .hero-text {
        margin-left: 42%;
        max-width: 500px;
    }
}

/* Mobile - Tablet devices */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .hero-text {
        margin-left: 40%;
        max-width: 400px;
    }

    .hero-text h1 {
        font-size: 32px;
        line-height: 52px;
    }

    .hero-text span {
        font-size: 30px;
    }
}

/* Mobile */
@media only screen and (max-width: 768px) {
    body {
        overflow-y: auto;
    }

    .hero {
        top: 32px;
        height: 250px;
        width: 250px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        position: absolute;
        z-index: 0;
    }

    .hero-text {
        left: 50%;
        margin: 32px 0 0 0;
        width: 85%;
        position: absolute;
        transform: translateX(-50%);
    }

    .hero-text h1 {
        font-size: 25px;
        line-height: 42px;
    }

    .hero-text span {
        font-size: 21px;
    }

    .hero-text p {
        padding-bottom: 50px;
    }

    .color-block {
        display: none;
    }

    .hero-text.entrance-down {
        animation: none;
    }
}

.hero-text span {
    color: white;
}

.hero-text p {
    color: white;
    margin: 15px 0 28px;
    font-size: 16px;
    line-height: 35px;
    text-align: justify;
    hyphens: auto;
}

.hero-text.fade-in {
    animation-name: fade-in;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

.hero.fade-in {
    animation-name: fade-in;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.color-block.entrance-right {
    animation-name: entrance-right;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes entrance-right {
    from {
        left: -200%;
        opacity: 0;
    }

    to {
        right: -83%;
        opacity: 1;
    }
}

.color-block.block-exit-up {
    animation-name: block-exit-up;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes block-exit-up {
    from {
        opacity: 1;
    }

    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}

.hero.hero-exit-up {
    animation-name: hero-exit-up;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.hero-text.hero-exit-up {
    animation-name: hero-exit-up;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes hero-exit-up {
    from {
        opacity: 1;
    }

    to {
        top: -200px;
        opacity: 0;
    }
}

.hero.entrance-down {
    animation-name: entrance-down-hero;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes entrance-down-hero {
    from {
        opacity: 0;
        top: -200px;
    }

    to {
        top: 40px;
    }
}

.hero-text.entrance-down {
    animation-name: entrance-down-text;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes entrance-down-text {
    from {
        opacity: 0;
        top: -200px;
    }

    to {
        top: 45vh;
    }
}